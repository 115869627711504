define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/layout',
  'modules/shop.cash-register-retail/templates/layouts/app/layout.hbs',

  'modules/shop.cash-register-retail/views/layouts/app/sidebarLeft',
  'modules/shop.cash-register-retail/views/layouts/app/sidebarRight',
  'modules/shop.cash-register-retail/views/layouts/app/header',
  'modules/shop.cash-register-retail/views/layouts/app/page',
  'modules/shop.cash-register-retail/views/layouts/app/fullscreenLoader',

  'modules/shop.cash-register-retail/models/notifications',
  'modules/shop.cash-register-retail/events/app/fullScreenLoader',
  'modules/common/subscribers/subscriber',
], (
  $, _, Backbone, Layout, Template,
  SidebarLeftView, SidebarRightView, HeaderView, PageView, FullscreenLoaderView,
  NotificationsModel, FullScreenLoaderEvent, Subscriber,
) => Layout.extend({
  template: Template,

  name: 'modules/shop.cash-register-retail/views/layouts/app/layout',

  regions: {
    sidebarLeft: {
      selector: '[data-region="sidebar-left"]',
      viewClass: SidebarLeftView,
    },
    sidebarRight: {
      selector: '[data-region="sidebar-right"]',
      viewClass: SidebarRightView,
    },
    header: {
      selector: '[data-region="page-header"]',
      viewClass: HeaderView,
    },
    page: {
      selector: '[data-region="page-content"]',
      viewClass: PageView,
    },
    'fullscreen-loader': {
      selector: '[data-region="fullscreen-loader"]',
    },
  },

  events: {
    'click [data-action="home"]': 'homeClicked',
  },

  ui: {
    logo: '[data-ui="logo"]',
    fullscreenLoader: '[data-region="fullscreen-loader"]',
  },

  homeClicked() {
    Backbone.history.navigate('news-letters/reload', { trigger: true });
  },

  showLoader(event) {
    const { deferred } = event.options;
    const region = this.getRegion('fullscreen-loader');
    this.ui.fullscreenLoader.show();
    region.show(new FullscreenLoaderView(event.options));
    deferred.always(() => {
      this.hideLoader();
    });
  },

  hideLoader() {
    this.ui.fullscreenLoader.hide();
    const region = this.getRegion('fullscreen-loader');
    if (region) {
      region.empty();
    } else {
      // console.error with the region variable
      console.error('Region not found `fullscreen-loader`', region);
    }
  },

  onRender() {
    this.hideLoader();
  },

  onShow() {
    this.startListeners();
    NotificationsModel.fetchNotifications();
  },

  onDestroy() {
    this.stopListeners();
  },

  startListeners() {
    NotificationsModel.on('change:unread_newsletters', this.updateLogo, this);
    this.fullscreenSubscriber = new (Subscriber.extend({
      events: [{
        event: FullScreenLoaderEvent,
        action: (event) => this.showLoader(event),
      }],
    }))();
  },

  stopListeners() {
    NotificationsModel.off('change:unread_newsletters', this.updateLogo, this);
    if (this.fullscreenSubscriber) {
      this.fullscreenSubscriber.unbind();
    }
  },

  updateLogo() {
    const unreadNotification = NotificationsModel.get('unread_newsletters');
    if (unreadNotification > 0) {
      const el = $('<div>')
        .html('<span class="has-notification">!</span>');
      this.ui.logo.html(el.html());
    } else {
      this.ui.logo.html('');
    }
  },

}));
