define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/templates/inputs/productSearchInputSelect2/productSearchInputSelect2.hbs',
  'modules/shop.cash-register-retail/templates/inputs/productSearchInputSelect2/result.hbs',
  'modules/shop.cash-register-retail/templates/inputs/productSearchInputSelect2/noResult.hbs',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/shopProduct',

  'upx.modules/ShopModule/models/ShopFlatProduct',

  'modules/shop.common/components/crypto',

  'modules/admin/components/select2',
], (
  $, _, Backbone,
  Template, ResultTemplate, NoResultTemplate,
  Locale, ShopProductComponent,
  ShopFlatProductModel,
  Crypto,
) =>
  /**
   * This view allows the user to search for products, Where an select event is fired on the view
   * when a products is selected and the following arguments are send:
   * Shop Product Id, ShopFlatProductModel and Product Type
   * @since 9.0
   */
  Backbone.Marionette.LayoutView.extend({
    template: Template,

    ui: {
      search: '[data-ui="shop-product-search-simple"]',
    },

    initialize({
      allowCreationFromChoice,
      select2Options,
      additionalDropdownClassName,
      eventPrefix,
      resetAfterSelection,
      selectOnOneSearchResult,
      initialData,
      itemsPerFetch,
      sorting,
      filters,
      showPrice,
      showSku,
      showImage,
      showStock,
      showProductType,
      showPricesWithTax,
    }) {
      this.eventPrefix = eventPrefix || '';
      this.additionalDropdownClassName = additionalDropdownClassName || '';
      this.initialData = initialData || null;
      this.itemsPerFetch = itemsPerFetch || 15;
      this.sorting = sorting || [];
      this.filters = filters || [];
      this.resetAfterSelection = _.isBoolean(resetAfterSelection) ? resetAfterSelection : true;
      this.selectOnOneSearchResult = _.isBoolean(selectOnOneSearchResult) ? selectOnOneSearchResult : false;
      this.showPrice = _.isBoolean(showPrice) ? showPrice : true;
      this.showSku = _.isBoolean(showSku) ? showSku : true;
      this.showImage = _.isBoolean(showImage) ? showImage : true;
      this.showStock = _.isBoolean(showStock) ? showStock : true;
      this.showProductType = _.isBoolean(showProductType) ? showProductType : true;
      this.showPricesWithTax = _.isBoolean(showPricesWithTax) ? showPricesWithTax : true;

      this.select2Options = _.extend({}, this.getDefaultSelect2Options(allowCreationFromChoice), select2Options);
      this.language = Locale.getLocale() || 'nl';
      this.products = {};
    },

    getDefaultSelect2Options(allowCreationFromChoice) {
      const self = this;
      const defaults = {
        containerCssClass: 'container-white form-control shop-product-search-simple__container',
        dropdownCssClass: 'dropdown-white shop-product-search-simple__dropdown',
        allowClear: true,
        placeholder: Locale.translate('type_to_search_for_a_product'),
        minimumInputLength: 2,
        width: '100%',
        formatResult(item) {
          return self.select2FormatResults(item);
        },
        formatNoMatches(item) {
          return self.select2FormatNoResults(item);
        },
        query(query) {
          self.select2Query(query);
        },
      };

      if (allowCreationFromChoice) {
        defaults.createSearchChoice = (text) => {
          const id = Crypto.uuid();

          return {
            id,
            text,
            model: new Backbone.Model({
              product_number: id,
              product_name: text,
              fromChoice: true,
            }),
          };
        };
      }

      return defaults;
    },

    clearSearch() {
      const self = this;
      // Timeout needed, Else the select2 isnt being cleared
      setTimeout(() => {
        self.setSearch('');
      });
    },

    setSearch(value) {
      if (this.ui.search.select2 !== undefined) {
        this.ui.search.select2('val', value);
      }
    },

    selfTrigger() {
      if (this.eventPrefix !== '') {
        arguments[0] = `${this.eventPrefix}:${arguments[0]}`;
      }
      this.trigger.apply(this, arguments);
    },

    onShow() {
      this.initializeSelect2();
    },

    initializeSelect2() {
      const options = this.select2Options;
      options.dropdownCssClass += ` ${this.additionalDropdownClassName}`;

      const self = this;
      this.ui.search.select2(options)
        .on('select2-selecting', (event) => {
          $(document.body).css('overflow', 'visible');
          self.select2Selected(event);
          if (self.resetAfterSelection) {
            self.clearSearch();
          }
        })
        .on('select2-clearing', (event) => {
          self.select2Clearing(event);
        })
        .on('select2-open', () => {
          $(document.body).css('overflow', 'hidden');
        })
        .on('select2-loaded', (e) => {
          this.selfTrigger('loaded', e);
          if (self.selectOnOneSearchResult) {
            const { more = false, results = [] } = e.items || {};
            if (!more && results.length <= 2) {
              let foundOne = null;
              results.forEach((data) => {
                if (data.id !== 0) {
                  if (foundOne === null) {
                    foundOne = data;
                  } else if (foundOne !== false) {
                    foundOne = null; // second one found, set it to false
                  }
                }
              });
              if (foundOne) {
                this.selectData(foundOne);
              }
            }
          }
        })
        .on('select2-close', () => {
          $(document.body).css('overflow', 'visible');
        })
        .on('select2-blur', () => {
          $(document.body).css('overflow', 'visible');
        });

      if (this.initialData) {
        this.ui.search.select2('data', this.initialData);
      }
    },

    openSelect2() {
      this.ui.search.select2('open');
    },

    closeSelect2() {
      this.ui.search.select2('close');
    },

    setSelect2Search(value, highlightAll = false) {
      this.ui.search.select2('search', value);

      if (highlightAll) {
        const searchSelect2 = $('#select2-drop.select2-drop-active');
        const searchInput = searchSelect2.find('#s2id_autogen2_search').get(0);
        if (searchInput) {
          searchInput.setSelectionRange(0, searchInput.value.length);
        }
      }
    },

    selectData(data) {
      this.select2Selected({ choice: data });
      this.ui.search.select2('data', data);
      this.closeSelect2();
    },

    select2Selected(event) {
      const { id } = event.choice;
      const { model } = event.choice;
      const type = model.get('flat_product.product.type');
      this.selfTrigger('select', id, model, type);
    },

    select2Clearing() {
      this.selfTrigger('clear');
    },

    select2Query: _.debounce(function (query) {
      const shopFlatProductModel = new ShopFlatProductModel();
      const naturalSearchParameters = {
        query: (query.term !== '') ? query.term : 0,
        lang: this.language.toUpperCase(),
        start: (query.page - 1) * this.itemsPerFetch,
        limit: this.itemsPerFetch,
        sort: this.sorting,
        filters: this.filters,
      };

      const self = this;

      $.when(
        shopFlatProductModel.naturalSearch(naturalSearchParameters),
      ).then((response) => {
        this.products = {}; // resetting the products
        // Setting up variables
        const results = [];
        const products = {};

        // Check if it is the search query changed, so we can add a new item
        if (naturalSearchParameters.start === 0 && response.data.length > 0) {
          results.push({
            disabled: true,
            id: 0,
            text: 'First Row', // This row should not be shown
            model: new Backbone.DeepModel({
              isFirst: true,
            }),
          });
        }

        // Looping true the response data
        _.each(response.data, (model) => {
          products[model.id] = new Backbone.DeepModel(model);

          results.push({
            id: model.id,
            text: model.flat_product.title,
            model: products[model.id],
          });
        });

        // Checking if there is more data to load.
        let more = false;
        if (response.count >= naturalSearchParameters.limit) {
          more = true;
        }

        self.products = products;
        query.callback({
          more,
          results,
        });
      }, () => {
        self.products = {};
        query.callback({
          more: false,
          results: [],
        });
        self.ui.search.select2('destroy');
      });
    }, 250),

    select2FormatNoResults(term) {
      return NoResultTemplate({
        text: Locale.translate('no_products_matches_{0}', term),
      });
    },

    select2FormatResults(item) {
      const data = item.model.toJSON();

      if (data.fromChoice) {
        return ResultTemplate(data);
      }

      data.showSku = this.showSku;
      data.showImage = this.showImage;
      data.showPrice = this.showPrice;
      data.showStock = this.showStock;
      data.showProductType = this.showProductType;
      data.showPricesWithTax = this.showPricesWithTax;

      if (data.flat_product) {
        const { product_stock: productStock } = data.flat_product.product;
        data.stockName = ShopProductComponent.getStockName(!!productStock.unlimited, !!data.backorder_value, productStock.value);
        data.stockCls = ShopProductComponent.getStockCls(!!productStock.unlimited, !!data.backorder_value, productStock.value);

        if (data.flat_product.product.type !== 'simple') {
          data.productTypeName = ShopProductComponent.getProductTypeName(data.flat_product.product.type);
        }
      }

      return ResultTemplate(data);
    },
  }));
