define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/inputs/productSearchInput.hbs',
  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/timer',
  'modules/shop.cash-register-retail/components/barcodeMatcher',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'upx.modules/ShopModule/models/ShopFlatProduct',

  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/components/productCache',
  'modules/shop.cash-register-retail/components/productSearch',
  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/productAddon',
  'modules/shop.cash-register-retail/components/configurableSelection',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

], (
  $, _, Backbone, Template, WithKeyboardBehaviour,
  KeyboardModel, Locale, Timer, BarcodeMatcher, OrderItemCollection, ShopFlatProductModel,
  MessagePopupView, ProductCache, ProductSearch, FeaturedAttributeCollection,
  CashRegisterApi, ProductAddon, ConfigurableSelection,
  DefaultShopConfigurationModel,
) =>
/**
     * Searches and adds the found product to the OrderItemCollection
     * this one is meant to be only used in modules/shop.cash-register-retail/views/checkouts/retail.js
     */
  Backbone.Marionette.LayoutView.extend({

    behaviors: {
      WithKeyboardBehaviour: {
        behaviorClass: WithKeyboardBehaviour,
      },
    },

    template: Template,

    ui: {
      search: '[data-ui="product-search"]',
      input: '[data-ui="product-search-input"]',
      popup: '[data-region="popupSearchInput"]',
    },

    events: {
      'click @ui.search': 'searchClicked',
    },

    searchClicked() {
      this.triggerMethod('search:clicked');
    },

    regions: {
      popup: '[data-region="popup"]',
    },

    initialize(options) {
      this.placeholder = this.options.placeholder;
      this.checkoutBtn = this.options.checkoutBtn;
      this.keyboardMode = this.keyboardModel.MODE_PRODUCT_SEARCH;
      this.currentValue = '';
      this.orderItemCollection = options.orderItemCollection || OrderItemCollection;
    },

    onShow() {
      this.checkSearchInterval = setInterval(() => {
        this.setProductSearch();
      }, 200);
    },

    setProductSearch() {
      // select this as mode when no mode is set
      if (this.keyboardModel.isNeutralMode()) {
        this.keyboardModel.productSearchMode(this.cid);
      }
    },

    onDestroy() {
      clearInterval(this.checkSearchInterval);
    },

    onModeSelected() {
    },
    onModeDeselected() {
    },
    onKeyPress(key) {
      if (this.keyboardModel.isAlphaNumeric(key)) {
        this.currentValue += key;
      }
    },

    onBackspacePress() {
      this.currentValue = this.removeLastCharacterFromString(this.currentValue);

      // when swapping this is called after the view is destroyed, no need to render
      if (!this.isDestroyed) {
        this.render();
      }
    },

    cleanValue() {
      this.currentValue = '';

      // when swapping this is called after the view is destroyed, no need to render
      if (!this.isDestroyed) {
        this.render();
      }
    },

    onConfirmation() {
      this.search();
      this.cleanValue();
    },

    getProductSearchParams(query) {
      const params = {
        query: 0,
        lang: 0,
        start: 0,
        limit: 2,
        filters: [
          {
            name: 'product_price/ppu__notnull',
            val: '1',
          },
        ],
      };
      const barcodeAttr = FeaturedAttributeCollection.getAttributeByAlias(
        FeaturedAttributeCollection.ALIAS_BARCODE,
      );
      if (!barcodeAttr) {
        console.error('No barcode attr is set in FeaturedAttributeCollection, will search for query instead');
      }
      if (barcodeAttr && query.match(/^\d+$/)) {
        const barcodes = [];
        const possibleBarcodes = ProductCache.getPossibleBarcodes(query);
        if (possibleBarcodes !== false) {
          possibleBarcodes.forEach((possibleBarcode) => {
            barcodes.push(`${barcodeAttr.get('attribute.name')}:${possibleBarcode}`);
          });
        }
        // barcode search
        params.filters.push({
          name: 'flat_product/content_var_items__overlap',
          multi_val: barcodes,
        });
      } else {
        params.query = query;
      }
      return params;
    },

    handleQuerySearch(query) {
      const productDef = Timer.createTimer('product_search_timer', { query });
      const def = new $.Deferred();

      ProductCache.findByBarcode(query).then(
        (cacheModel) => {
          const model = new ShopFlatProductModel(cacheModel.get('shop_flat_product'));
          if (!model.get('product_price.id')) {
            this.showError(
              Locale.translate('product_was_found_but_there_was_no_price_set_searched_for_{0}', query),
            );
            productDef.reject(new Error('Product was found but there is no price'));
          } else {
            productDef.resolve(model);
          }
        },
        () => {
          const shopFlatProductModel = new ShopFlatProductModel();
          const params = this.getProductSearchParams(query);
          shopFlatProductModel.naturalSearch(params)
            .then(
              (searchResponse) => {
                if (searchResponse.total > 1) {
                  console.warn(`Multiple products found for ${query}`);
                }

                if (searchResponse.total === 1) {
                  const dataArray = searchResponse.data;
                  const first = _.first(dataArray);
                  const model = new ShopFlatProductModel(first);
                  ProductCache.storeShopProduct(model);
                  productDef.resolve(model);
                } else {
                  // Write the missing barcodes to a logfile
                  this.logMissingBarcode(query);
                  if (!this.isDestroyed) {
                    this.showError(`${Locale.translate('could_not_match_a_product')}: ${query}`);
                  }
                  this.cleanValue();
                  productDef.reject(new Error('Product was not found'));
                }
              },
              productDef.reject,
            );
        },
      );

      productDef.then(
        (model) => {
          def.resolve();
          this.orderItemCollection.addProductByShopFlatProductModel(model).then(
            (orderRow) => this.orderItemCollection
              .refreshProductStockIfProductStockIsUsed(orderRow),
          );
        },
        def.reject,
      );
      return def.promise();
    },
    handleWeightedBarcode(specialMatch) {
      const def = new $.Deferred();
      const productBarcode = specialMatch.barcode;

      // Searching for the product
      ProductSearch.searchProduct(productBarcode)
        .then(
          (searchResponse) => {
            const needsWeight = FeaturedAttributeCollection.getFeatureAttributeByAliasFromShopFlatProduct(
              searchResponse.model,
              FeaturedAttributeCollection.ALIAS_NEEDS_WEIGHT_ON_KASSA,
            ) === '1';

            if (needsWeight) {
              // clean the needs weight becasue we read it from barcode
              FeaturedAttributeCollection.setFeatureAttributeByAliasFromShopFlatProduct(
                searchResponse.model,
                FeaturedAttributeCollection.ALIAS_NEEDS_WEIGHT_ON_KASSA,
                0,
              );
            }
            def.resolve(searchResponse);
            this.orderItemCollection.addProductByShopFlatProductModel(
              searchResponse.model, 1, true,
            ).then(
              (model) => {
                model.setPpuWt(specialMatch.price);
              },
            );
          },
          def.reject,
        );

      return def;
    },

    handleEmballageBarcode(specialMatch) {
      const def = new $.Deferred();
      // input is emballage (dutch: statiegeld) EAN (validated) barcode
      // e.g. query = 9801234504509
      DefaultShopConfigurationModel.getEmballageShopFlatProductModel().then(
        (emballageProduct) => {
          def.resolve();
          this.orderItemCollection.addProductByShopFlatProductModel(
            emballageProduct, -1, true,
          ).then(
            (item) => {
              item.setPpuWt(specialMatch.price);
              item.set({
                description: specialMatch.query,
                type: OrderItemCollection.TYPE_EMBALLAGE,
              });
              item.save();
            },
          );
        },
        (error) => {
          this.showError(Locale.translate('could_not_load_emballage_product'));
          def.reject(error);
        },
      );
      return def.promise();
    },

    search() {
      const self = this;
      let query = this.currentValue || '';
      query = query.trim();

      if (query.length > 0) {
        const def = new $.Deferred();
        this.orderItemCollection.addLoaderByDef(def);
        try {
          this.checkoutBtn.attr('disabled', true);
          def.always(() => {
            if (!_.isString(self.checkoutBtn)) {
              self.checkoutBtn.attr('disabled', false);
            }
          });
          const specialMatch = BarcodeMatcher.match(query);
          if (specialMatch.type === BarcodeMatcher.TYPE_EMBALLAGE) {
            console.debug(`[Product search] TYPE_EMBALLAGE ${query}`);
            this.handleEmballageBarcode(specialMatch).then(def.resolve, def.reject);
          } else if (specialMatch.type === BarcodeMatcher.TYPE_WEIGHTED) {
            console.debug(`[Product search] TYPE_WEIGHTED ${query}`);
            this.handleWeightedBarcode(specialMatch)
              .then(
                def.resolve,
                (resp) => {
                  console.debug(`[Product search] TYPE_WEIGHTED -> TYPE_NORMAL ${query} `, resp);
                  this.handleQuerySearch(query).then(def.resolve, def.reject);
                },
              );
          } else {
            console.debug(`[Product search] TYPE_NORMAL ${query}`);
            this.handleQuerySearch(query).then(def.resolve, def.reject);
          }
        } catch (e) {
          this.showError(e.message);
          def.reject(e);
        }
        def.fail((error) => {
          console.warn(`[Product search] Search for ${query} failed `, error);
        });
      }
    },

    showError(message) {
      new MessagePopupView().open(message);
    },

    logMissingBarcode(query) {
      CashRegisterApi.log('missing_barcode', 'BARCODE_WITHOUT_PRODUCT', query)
        .fail((errorResponse) => {
          // Make sure if call to the CashRegisterApi fails
          console.warnOriginal('Failed to log the "missing_barcode"', query, errorResponse);
        });
    },

    onCancel() {
      this.cleanValue();
    },

    serializeData() {
      return {
        currentValue: this.currentValue,
        placeholder: this.placeholder,
      };
    },
  }));
