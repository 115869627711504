define([
  'application',
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/login/login.hbs',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/components/toaster',

  'modules/common/components/locale',
  'backbone.syphon',
  'modules/shop.cash-register-retail/models/user',
  'modules/shop.cash-register-retail/models/pinKey',
  'modules/admin/behaviors/loader',

  'modules/shop.common/components/securityCodes',
  'modules/shop.cash-register-retail/components/environment',
  'modules/shop.common/components/deviceConfig',

],
(
  App, $, _, Backbone, Template,
  KeyboardModel, Toaster,
  Locale, Syphon, User, PinKeyModel, Loader,
  SecurityCodes, EnvComp, DeviceConfig,
) => Backbone.Marionette.ItemView.extend({
  template: Template,

  className: 'login-flex',

  ui: {
    form: 'form',
    formInputs: '#login input',
    registerBtn: 'button[js-ui="register"]',
    pinKeyboard: '[data-ui="pin"]',
    deviceInfo: '.device-info',
  },

  events: {
    'click [data-action=pin] > [data-value]': 'pinButtonClicked',
    'submit #login': 'login',
    'keydown @ui.formInputs': 'onInputForm',
    'click @ui.deviceInfo': 'onDeviceInfoClick',
  },

  behaviors: {
    FormLoader: {
      behaviorClass: Loader,
    },
  },

  SCANNER_NONE: 0,
  SCANNER_DATA: 2,
  SCANNER_DATA_END: 3,

  initialize(options) {
    options = options || {};

    this.successRedirectRoute = options.successRedirectRoute;
    if (App.settings.admin !== undefined && App.settings.admin.tree_root_shortname !== undefined) {
      this.shortname = App.settings.admin.tree_root_shortname;
    }
    this.code = '';
    this.scannerMode = this.SCANNER_NONE;
    this.pin = '';
    this.pinEnabled = PinKeyModel.isEnabled();
  },

  onDeviceInfoClick() {
    this.ui.deviceInfo.addClass('show-me');
  },
  onInputForm(e) {
    const $el = $(e.currentTarget);
    let qrMode = false;

    const val = $el.val();
    const l = val.length - SecurityCodes.SECURE_PREFIX.length;
    if (l >= 0
                && SecurityCodes.SECURE_PREFIX !== this.code.substring(l)) {
      qrMode = this.scannerMode !== this.SCANNER_NONE;
    }
    if (qrMode) {
      $el.val('');
      this.ui.formInputs.prop('disabled', true);
    }
  },

  login: _.debounce(function (e) {
    if (this.scannerMode !== this.SCANNER_NONE) {
      return; // scanning do not send
    }

    // Grab the form data
    const self = this;
    const data = Syphon.serialize(this.ui.form.get(0));
    if (!data.email || !data.password) {
      return; // email or passoword empty
    }
    // Prevent the default action of the form
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const def = this.loader.startLoader();

    // Pass the given data to the user model
    const user = new User();
    user.setEmail(data.email, this.shortname);
    user.setPassword(data.password);

    // Try to login
    $.when(user.login({
      longExpiryHash: true,
    })).then(
      () => {
        // all ok,
        self.triggerMethod('setUser', def, user);
      },
      () => {
        Toaster.error(Locale.translate('wrong_e_mailpassword_combination'));
        def.reject();
      },
    );
  }, 200),

  pinButtonClicked(e) {
    if (this.pinEnabled) {
      const el = e.currentTarget;
      const valueString = el.dataset.value;
      if (valueString === 'back') {
        this.pin = this.pin.substring(0, this.pin.length - 1);
      } else if (this.pin.length < 4) {
        this.pin += valueString;
      }
      this.pinChanged();
    }
  },

  pinChanged() {
    for (let i = 0; i < 4; i++) {
      const $el = this.$el.find(`[data-index=${i}]`);
      const value = this.pin[i];
      if (value) {
        $el.addClass('fas');
      } else {
        $el.removeClass('fas');
      }
    }
    if (this.pin.length === 4) {
      const self = this;

      this.loginUsingPin(this.pin).then(
        () => {
          if (self.successRedirectRoute) {
            Backbone.history.navigate(self.successRedirectRoute, { trigger: true });
          } else {
            Backbone.history.navigate('checkout', { trigger: true });
          }
        }, () => {
          self.pin = '';
          self.pinChanged();
          Toaster.error(Locale.translate('pin_is_incorrect'));
        },
      );
    }
  },

  loginUsingPin(pin) {
    const user = new User();
    const self = this;
    const def = this.loader.startLoader();
    $.when(user.loginUsingPin(pin, {
      longExpiryHash: true,
    })).then(
      () => {
        self.triggerMethod('setUser', def, user);
      },
      () => {
        def.reject();
      },
    );
    return def;
  },

  onShow() {
    KeyboardModel.on('key:confirm', this.login, this);

    const loginContainer = $('#login-container');
    window.addEventListener('keyboardDidShow', (e) => {
      loginContainer.addClass('keyboard');
    });
    window.addEventListener('keyboardDidHide', (e) => {
      loginContainer.removeClass('keyboard');
    });

    if (!this.pinEnabled) {
      this.ui.pinKeyboard.addClass('disabled');
      Toaster.warning(Locale.translate('pin_login_isn_t_enabled_on_this_device_you_can_enable_it_in_the_settings_screen_after_logging_in_as_an_administrative_user_dot'));
    }
  },

  onRender() {
    const self = this;
    $(document).on('keydown.loginQrScan', (ev) => {
      self.handleKeyboardEvent(ev);
    });
  },
  onDestroy() {
    KeyboardModel.off('key:confirm', this.login, this);

    $(document).off('keydown.loginQrScan');
  },
  /**
         * no used but really handy for debugging
         * @param event
         * @returns {string}
         */
  keyDef(event) {
    let def = `${event.key}\t[`;
    if (event.code) {
      def += `c:${event.code}`;
    }
    if (event.ctrlKey) {
      def += ' CTRL';
    }
    if (event.metaKey) {
      def += ' META';
    }
    if (event.shiftKey) {
      def += ' SHIFT';
    }
    return `${def}]`;
  },
  /**
         * @param event KeyboardEvent
         */
  handleKeyboardEvent(event) {
    const keyPressed = event.key;

    if (keyPressed !== 'Shift') {
      const self = this;
      // ignore all shifts
      if (this.scannerMode === this.SCANNER_NONE) {
        // wait for $ as start of the secure sting
        if (keyPressed === '$') {
          this.scannerMode = this.SCANNER_DATA;
          this.code = '$';
          this.qrLog('start');
          self.validatePrefix();
        }
      } else if (this.scannerMode === this.SCANNER_DATA) {
        // continue until the prefix is complete
        if (keyPressed !== 'Enter') {
          this.qrLog('data');
          this.code += keyPressed;
          self.validatePrefix();
        } else {
          this.scannerMode = this.SCANNER_DATA_END;
          this.qrLog('Enter');
          this.validateCode(); // start code
        }
      }
    }
  },

  qrLog(text) {
    if (!EnvComp.isDevelopment()) {
      return; // no loging if not as dev
    }

    const { code } = this;
    const { scannerMode } = this;
    setTimeout(() => {
      console.log(`[QR: ${code} ] ${text} Mode ${scannerMode}`);
    }, 100);
  },

  resetMode: _.debounce(function () {
    this.scannerMode = this.SCANNER_NONE;
    this.code = '';

    // Check if the view still exists
    if (!_.isString(this.ui.formInputs) && this.ui.formInputs.get(0)) {
      // make the input field available again so you login using password
      this.ui.formInputs.prop('disabled', false);
    }
    this.qrLog('reset');
  }, 5),

  validatePrefix: _.debounce(function () {
    if (this.scannerMode === this.SCANNER_DATA) {
      const l = SecurityCodes.SECURE_PREFIX.length;
      if (SecurityCodes.SECURE_PREFIX !== this.code.substring(0, l)) {
        this.qrLog('Not a valid prefix');
        this.resetMode();
      }
    } else {
      this.qrLog('Not in data mode');
      this.resetMode();
    }
  }, 50),

  validateCode() {
    const self = this;
    if (this.scannerMode === this.SCANNER_DATA_END) {
      // probably a valid code, check if ends correctly
      const p = this.code.length - SecurityCodes.SECURE_SUFFIX.length;
      if (SecurityCodes.SECURE_SUFFIX === this.code.substring(p)) {
        if (this.pinEnabled) {
          this.qrLog('valid');
          this.loginUsingPin(this.code).then(() => {
            self.resetMode();
          }, () => {
            self.resetMode();
            Toaster.error(Locale.translate('invalid_security_code'));
          });
        } else {
          this.qrLog('Pin login is not enabled');
          Toaster.error(Locale.translate('pin_login_is_not_enabled'));
          this.resetMode();
        }
      } else {
        this.qrLog('Not a valid prefix');
        this.resetMode();
      }
    } else {
      this.qrLog('NOT valid ');
      this.resetMode();
    }
  },

  serializeData() {
    const uuid = DeviceConfig.getDeviceUUID();
    let version = 'dev';
    if (!!window.storekeeper && 'version' in window.storekeeper) {
      version = window.storekeeper.version;
    }
    let account = 'UNKNOWN';
    if (App.settings && App.settings.upx && App.settings.upx.account) {
      account = App.settings.upx.account;
    }
    return {
      deviceUuid: uuid,
      version,
      account,
    };
  },
}));
