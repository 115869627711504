define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/templates/inputs/currencyInput',
], (
  $, _, Backbone, WithKeyboardBehaviour, Currency, Template,
) => Backbone.Marionette.ItemView.extend({

  template: Template,

  behaviors() {
    if (!this.disabled) {
      const resetOnConfirmation = _.isBoolean(this.options.resetOnConfirmation) ? this.options.resetOnConfirmation : true;
      return {
        WithKeyboardBehaviour: {
          behaviorClass: WithKeyboardBehaviour,
          resetOnConfirmation,
        },
      };
    }
    return {};
  },

  ui: {
    input: 'input',
  },

  initialize({
    left,
    disabled,
  }) {
    this.disabled = disabled || false;
    this.keyboardMode = this.keyboardModel.MODE_CASH;
    this.firstInput = true;
    this.maxValue = null;
    if ('maxValue' in this.options) {
      this.maxValue = this.options.maxValue;
    }

    this.attributeName = 'value';
    if ('attributeName' in this.options) {
      this.attributeName = this.options.attributeName;
    }

    if (!('model' in this.options)) {
      this.model = new Backbone.Model();
      this.model.set(this.attributeName, '0.00');
    }

    if ('startValue' in this.options) {
      this.model.set(this.attributeName, Currency.toCurrency(this.options.startValue));
    }
    this.currentValue = this.model.get(this.attributeName);

    const self = this;

    this.model.on(`change:${this.attributeName}`, (model, value) => {
      self.currentValue = value;
      if (!self.isDestroyed) {
        self.render();
      }
    });

    this.alignRight = left ? !left : true;
  },

  serializeData() {
    let classes = 'form-control';
    if (this.alignRight) {
      classes += ' text-right';
    }
    return { classes, disabled: this.disabled };
  },

  getValue() {
    return this.model.get(this.attributeName);
  },

  setValue(value) {
    this.model.set(this.attributeName, value);
  },

  onKeyPress(key) {
    if (!this.disabled && this.keyboardModel.isNumberComaKey(key)) {
      let currentPrice = this.currentValue;

      if (this.firstInput) {
        currentPrice = '0.00';
        this.firstInput = false;
      }

      let newPrice = this.handleInputOnPrice(currentPrice, key);

      if (this.maxValue && parseFloat(newPrice) > parseFloat(this.maxValue)) {
        newPrice = Currency.toCurrency(this.maxValue);
      }

      if (parseFloat(newPrice) > 100000.00) {
        newPrice = '100000.00';
      }

      this.currentValue = newPrice;
    }
  },

  onBackspacePress() {
    if (!this.disabled) {
      // Mark input as changed
      this.firstInput = false;
      // Update the value
      this.currentValue = this.removeDigitFromPrice(this.currentValue);
    }
  },

  onRender() {
    this.ui.input.val(Currency.format('EUR', this.currentValue));
  },

  onConfirmation() {
    this.model.set(this.attributeName, this.currentValue);

    // Timeout needed to prevent unwanted triggering when putting the focus on another input after this one.
    setTimeout(() => {
      this.triggerMethod('view:confirm', this.currentValue);
    }, 10);
  },

  onCancel() {
    this.currentValue = this.model.get(this.attributeName);
    if (!this.isDestroyed) {
      this.render();
    }
  },

  setFocus() {
    if (!this.disabled) {
      this.keyboardModel.setViewWithMode(this.cid, this.keyboardMode);
    }
  },
}));
