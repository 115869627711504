define([
  'application',
  'jquery',
  'module',
  'backbone',
  'modules/common/views/swappableWithLoading',
  'modules/shop.cash-register-retail/templates/login/swappable',

  './login',
  './loginByHash',

  'modules/common/components/historyBreadcrumb',
  'modules/admin/events/redirect/homePage',
  'modules/upx/components/upx',
  'modules/upx/collections/users',
  'modules/admin/templates/alerts/danger',
  'modules/profile/models/profile',

  'modules/shop.cash-register-retail/models/customerScreenData',
],
(App, $, Module, Backbone, SwappableView, Template,
  LoginView, LoginByHash,
  HistoryBreadcrumb, RedirectHomeEvent, Upx, Users, DangerAlert, ProfileModel,
  CustomerScreen) => SwappableView.extend({

  childEvents: $.extend({
    routeAfterLogin: 'routeAfterLogin',
    logoutUser: 'logoutUser',
    setUser: 'setUser',
    showError: 'showError',
  }, SwappableView.prototype.childEvents),

  className: 'login-container',

  template: Template,

  ui: {
    messages: '#messages',
  },
  /**
             * Bind each part of the form to 2 different models which each their own validation
             */
  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(LoginView);

    this.setView('loginByHash', LoginByHash, {
      data: options.data,
    });
  },

  routeAfterLogin(view) {
    Backbone.history.navigate(
      HistoryBreadcrumb.getIndexFragment(),
      { trigger: true },
    );
  },

  logoutUser(view) {
    Upx.call('ManagementModule', 'endSession');
    const user = Users.findWhere({ active: true });
    if (user) {
      user.logout();
    }
  },

  setUser(view, def, user) {
    const self = this;
    user.set('active', true);
    Users.add(user);
    user.save();
    // fetch profile
    $.when(ProfileModel.load(user)).then(
      () => {
        def.resolve();
        self.routeAfterLogin();
      },
      () => {
        self.logoutUser();
        def.reject();
      },
    );
  },

  showError(view, title, description) {
    const loginDiv = this.$el;
    loginDiv.addClass('animation-shake');

    const alert = DangerAlert({
      id: 'wrong',
      dismissable: true,
      title,
      description,
    });

    this.ui.messages.empty();
    this.ui.messages.prepend(alert);

    window.setTimeout(() => {
      loginDiv.removeClass('animation-shake');
    }, 500);
  },

  onShow() {
    SwappableView.prototype.onShow.apply(this);
    CustomerScreen.set('onLoginScreen', true);
    CustomerScreen.save();
  },
  onDestroy() {
    CustomerScreen.set('onLoginScreen', false);
    CustomerScreen.save();
  },
}));
