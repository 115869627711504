define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/layouts/app/connectionQuality.hbs',
  'modules/shop.cash-register-retail/models/connection',
], (
  $, _, Backbone, Template, ConnectionModel,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  classStatusGood: 'connection-good',
  classStatusMedium: 'connection-medium',
  classStatusBad: 'connection-bad',

  ui: {
    connectionQuality: '[data-ui="connection-status"]',
  },

  onShow() {
    this.updateConnectionQuality();
    ConnectionModel.on('change:connectionQuality', this.updateConnectionQuality, this);
  },

  onDestroy() {
    ConnectionModel.off('change:connectionQuality', this.updateConnectionQuality, this);
  },

  updateConnectionQuality() {
    this.resetNetworkStatus();

    const element = this.ui.connectionQuality;
    const status = ConnectionModel.get('connectionQuality');

    switch (status) {
      case ConnectionModel.connectionQualityMedium:
        element.addClass(this.classStatusMedium);
        break;
      case ConnectionModel.connectionQualityBad:
        element.addClass(this.classStatusBad);
        break;
      case ConnectionModel.connectionQualityGood:
      default:
        element.addClass(this.classStatusGood);
        break;
    }
  },

  resetNetworkStatus() {
    const element = this.ui.connectionQuality;

    element.removeClass(this.classStatusGood);
    element.removeClass(this.classStatusMedium);
    element.removeClass(this.classStatusBad);
  },

  serializeData() { return {}; },
}));
