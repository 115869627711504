define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/common/components/currency',
], (
  $, _, Backbone, WithKeyboardBehaviour, Currency,
) => Backbone.Marionette.LayoutView.extend({

  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
      preventRender: true,
    },
  },

  ui: {
    input: 'input',
  },

  template() {
    return '<input type="text" readonly="readonly" ignore-keyboard="true" class="form-control text-right themed-background-light" placeholder="">';
  },

  initialize() {
    this.keyboardMode = this.keyboardModel.MODE_PRODUCT_PPU;
  },

  onModeSelected() {
    this.previousPpu = this.model.get('ppu_wt');
  },

  onModeDeselected() {
    this.resetPrice();
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberComaKey(key)) {
      let currentPrice = this.model.get('ppu_wt');
      if (this.firstInput) {
        currentPrice = '0.00';
        this.firstInput = false;
      }
      let newPrice = this.handleInputOnPrice(currentPrice, key);
      if (parseFloat(newPrice) > 100000.00) {
        newPrice = '100000.00';
      }

      this.model.set({
        ppu_wt: newPrice,
      });
    }
  },

  onBackspacePress() {
    // Mark price as changed
    this.firstInput = false;
    // Update the price
    const newPrice = this.removeDigitFromPrice(this.model.get('ppu_wt'));
    this.model.set('ppu_wt', newPrice);
  },

  onShow() {
    this.model.on('change:ppu_wt', this.applyValue, this);
    this.applyValue();
  },

  onDestroy() {
    this.model.off('change:ppu_wt', this.applyValue, this);
  },

  applyValue() {
    const ppu_wt = this.model.get('ppu_wt') || '0.00';
    const currency_iso = this.model.get('currency_iso3') || 'EUR';
    const ppu_wt_formatted = Currency.Math.format(currency_iso, ppu_wt);
    this.ui.input.val(ppu_wt_formatted);
  },

  onConfirmation() {
    this.confirmPrice();
  },

  confirmPrice() {
    this.previousPpu = this.model.get('ppu_wt');
  },

  resetPrice() {
    this.model.set({
      ppu_wt: this.previousPpu,
    });
  },

  onCancel() {
    this.resetPrice();
  },

}));
