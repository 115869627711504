define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/inputs/companyKvkSearchInput',
  'modules/shop.cash-register-retail/templates/inputs/companyKvkSearchInputResult.hbs',

  'upx.modules/RelationsModule/models/Relation',

  'modules/admin/components/select2',
], (
  $, _, Backbone, Template, ResultTemplate,
  RelationModel,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'company-kvk-search-input',

  ui: {
    input: '[data-ui="input"]',
  },

  events: {
    'click [data-iso2]': 'iso2Clicked',
    'keyup @ui.input': 'inputKeyup',
  },

  supportedCountryIso2s: ['NL', 'GB', 'BE'],

  initialize(options) {
    this.placeholderSearch = options.placeholderSearch;
    this.placeholderInput = options.placeholderInput;
    this.countryIso2 = options.countryIso2;
    this.companyName = options.companyName;
  },

  iso2Clicked(ev) {
    this.countryIso2 = ev.currentTarget.dataset.iso2 || '';
    this.render();

    this.triggerMethod('change:iso2', this.countryIso2);
  },

  inputKeyup(ev) {
    this.companyName = ev.currentTarget.value;
    this.triggerMethod('change:name', this.companyName);
  },

  onRender() {
    this.renderSearch();
  },

  renderSearch() {
    if (this.supportedCountryIso2s.indexOf(this.countryIso2) !== -1) {
      this.ui.input.select2({
        placeholder: this.placeholderSearch,
        containerCssClass: 'container-white form-control company-kvk-search-input__container',
        dropdownCssClass: 'dropdown-white company-kvk-search-input__dropdown',
        minimumInputLength: 2,
        width: '100%',
        query: (query) => this.querySearch(query),
        formatResult: (result) => ResultTemplate(result.data),
      }).on('select2-selecting', (ev) => {
        this.triggerMethod('company:selected', ev.choice.data);
      });

      if (this.companyName) {
        this.ui.input.select2('data', { text: this.companyName });
      }
    } else {
      this.ui.input.select2('destroy');
    }
  },

  querySearch(query) {
    const { term, page, callback } = query;
    const limit = 10;
    const start = (page - 1) * limit;
    const relationModel = new RelationModel();
    const parameters = {
      query: term,
      country_iso2: this.countryIso2,
      start,
      limit,
    };

    relationModel.searchDataByName(parameters)
      .then(({ data, count }) => {
        const results = data.map((item) => ({
          id: JSON.stringify(item),
          text: item.business_data.name,
          data: item,
        }));
        if (page === 1) {
          results.push({
            id: 0,
            text: term,
            data: {
              business_data: {
                name: term,
              },
            },
          });
        }
        callback({
          more: count === limit,
          results,
        });
      });
  },

  serializeData() {
    return {
      placeholderInput: this.placeholderInput,
      companyName: this.companyName,
    };
  },

}));
