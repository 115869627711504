define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/keypads/main',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Template, keyboardModel,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'keypad keypad-percentage',

  events: {
    'click button': 'buttonClicked',
    'touchend button': 'buttonClicked',
  },

  modelEvents: {
    'change:highlightedKeys': 'keysChanged',
    'change:mode': 'modelChanged',
  },

  ui: {
    enter: '[data-key="Enter"]',
    'change:mode': 'keysChanged',
  },

  modelChanged() {
    if (keyboardModel.isModeWithConfirmation()) {
      this.ui.enter.addClass('btn-pulse');
    } else {
      this.ui.enter.removeClass('btn-pulse');
    }
  },

  keysChanged() {
    const self = this;
    const keys = keyboardModel.get('highlightedKeys');
    self.$el.find('[data-key]').removeClass('selected');
    keys.forEach((value) => {
      const el = self.$el.find(`[data-key="${value}"]`);
      if (el) {
        el.addClass('selected');
      }
    });
  },

  onShow() {
    this.keysChanged();
  },

  buttonClicked: _.debounce((e) => {
    e.stopPropagation(); // increases the touch speed
    e.preventDefault(); // increases the touch speed
    const $el = $(e.currentTarget);
    $el.blur();

    const eventObj = document.createEventObject
      ? document.createEventObject() : document.createEvent('Events');

    if (eventObj.initEvent) {
      eventObj.initEvent('keydown', true, true);
    }

    eventObj.code = $el.data('code').toString();
    eventObj.key = $el.data('key').toString();

    document.dispatchEvent ? document.dispatchEvent(eventObj) : document.fireEvent('onkeydown', eventObj);
  }, 100),

  keys: [
    {
      key: '0',
      code: '0',
      class: '0',
      text: '0',
    },
    {
      key: '00',
      code: '00',
      class: '00',
      text: '00',
    },
    {
      key: '1',
      code: '1',
      class: '1',
      text: '1',
    },
    {
      key: '2',
      code: '2',
      class: '2',
      text: '2',
    },
    {
      key: '3',
      code: '3',
      class: '3',
      text: '3',
    },
    {
      key: '4',
      code: '4',
      class: '4',
      text: '4',
    },
    {
      key: '5',
      code: '5',
      class: '5',
      text: '5',
    },
    {
      key: '6',
      code: '6',
      class: '6',
      text: '6',
    },
    {
      key: '7',
      code: '7',
      class: '7',
      text: '7',
    },
    {
      key: '8',
      code: '8',
      class: '8',
      text: '8',
    },
    {
      key: '9',
      code: '9',
      class: '9',
      text: '9',
    },
    {
      key: '%',
      code: '%',
      class: 'percentage',
      text: '%',
    },
    {
      key: 'Enter',
      code: 'Enter',
      class: 'enter btn-success',
      text: null,
      iconCls: 'fas fa-check',
    },
    {
      key: ',',
      code: ',',
      class: 'comma',
      text: ',',
    },
    {
      key: 'Backspace',
      code: 'Backspace',
      class: 'clear',
      text: 'CE',
    },
    {
      key: 'Escape',
      code: 'Escape',
      class: 'cancel',
      text: null,
      iconCls: 'fal fa-times-circle',
    },
  ],

  serializeData() {
    return {
      keys: this.keys,
    };
  },

}));
